import PropTypes from '@root/vendor/prop-types';
import { Layout } from '@root/brand/src/utils/layout';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function SceneWrapper({
  children, className, hasFooter,
}) {
  return (
    <div
      className={className}
      css={[styles.wrapper, hasFooter ? styles.heightWithFooter : styles.height]}
    >
      {children}
    </div>
  );
}

SceneWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasFooter: PropTypes.bool,
};

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  height: {
    minHeight: `calc(100vh - ${Layout.navbarHeight()}px)`,
  },

  heightWithFooter: {
    minHeight: `calc(100vh - ${Layout.navbarHeight() + Layout.STICKY_FOOTER_HEIGHT}px)`,
  },
});
