import Colors from '@root/brand/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function InputError({ message }) {
  const errorLabelStyles = [styles.errorLabel];

  if (message) {
    errorLabelStyles.push(styles.hasError);
  }

  return (
    <div
      aria-label={message}
      css={[errorLabelStyles, message ? styles.active : styles.collapsed]}
      role={'alert'}
    >
      {message}
    </div>
  );
}

InputError.propTypes = {
  message: PropTypes.string,
};

const styles = StyleSheet.create({
  errorLabel: {
    ...Theme.input(),
    color: Colors.error(),
    opacity: 0,
    transform: 'translateY(-150%)',
  },
  hasError: {
    marginTop: 5,
    opacity: 1,
    transform: 'translateY(0)',
  },
  active: {
    height: '1em',
    transition: 'height .25s ease-out',
    visibility: 'visible',
  },
  collapsed: {
    height: '0px',
    transition: 'height .25s ease-out',
    visibility: 'hidden',
  },
});
