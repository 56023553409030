import AuthService from '@root/core/src/services/auth-service';
import environment from '@root/core/src/utils/environment';
import mixpanel from 'mixpanel-browser';

export default class AnalyticsService {
  static defaultEventPrefix = 'WEB_APP';

  static trackEvent(context, event, properties = {}) {
    AnalyticsService._track([context, event], properties);
  }

  static trackViewEvent(context, properties = {}) {
    AnalyticsService._track([context, 'VIEWED'], properties);
  }

  static trackClickEvent(context, clickContext, properties = {}) {
    AnalyticsService._track([context, 'CLICKED', clickContext], properties);
  }

  static alias(userId) {
    mixpanel.alias(userId);
  }

  static getDistinctId() {
    if (typeof window !== 'undefined') {
      AnalyticsService.initMixpanel();
      return mixpanel.get_distinct_id();
    }
    return 'some-distinct-id';
  }

  static initMixpanel(identity) {
    mixpanel.init(environment.mixpanelToken);
    if (identity) { mixpanel.identify(identity); }
  }

  static trackLinks(selector, eventName, properties = {}) {
    mixpanel.track_links(selector, eventName.toUpperCase(), properties);
  }

  static register(properties) {
    mixpanel.register(properties);
  }

  static reset() {
    mixpanel.reset();
  }

  static _track(contexts, { eventPrefix = AnalyticsService.defaultEventPrefix, ...properties } = {}) {
    const eventName = AnalyticsService._getEventName(contexts, eventPrefix);
    const { userId, accountId } = AuthService.getCurrentUserContext();

    AnalyticsService.initMixpanel(userId);

    mixpanel.track(eventName, {
      ...properties,
      accountId,
    });
  }

  static _getEventName(contexts, eventPrefix) {
    return [eventPrefix].concat(...contexts).join('_').toUpperCase();
  }
}
