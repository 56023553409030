export default class KeyCode {
  static KEYCODES = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    ESC: 27,
    SPACE: 32,
    UP: 38,
    DOWN: 40,
    '0': 48,
    '1': 49,
    '2': 50,
    '3': 51,
    '4': 52,
    '5': 53,
    '6': 54,
    '7': 55,
    '8': 56,
    '9': 57,
    A: 65,
    B: 66,
    C: 67,
    D: 68,
    E: 69,
    F: 70,
    G: 71,
    H: 72,
    I: 73,
    J: 74,
    K: 75,
    L: 76,
    M: 77,
    N: 78,
    O: 79,
    P: 80,
    Q: 81,
    R: 82,
    S: 83,
    T: 84,
    U: 85,
    V: 86,
    W: 87,
    X: 88,
    Y: 89,
    Z: 90,
  };

  static isCharacter(keyCode) {
    return KeyCode.isLetter(keyCode) || KeyCode.isNumber(keyCode) || keyCode === KeyCode.KEYCODES.SPACE;
  }

  static isLetter(keyCode) {
    return keyCode >= KeyCode.KEYCODES.A && keyCode <= KeyCode.KEYCODES.Z;
  }

  static isNumber(keyCode) {
    return keyCode >= KeyCode.KEYCODES['0'] && keyCode <= KeyCode.KEYCODES['9'];
  }

  static fromEvent(event) {
    return event.which || event.keyCode;
  }
}
