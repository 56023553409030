import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Shadows from '@root/brand/src/utils/shadows';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { IconChevron } from '@root/core/src/components/icons/chevron';

export default function SelectBox({
  autoComplete,
  children,
  disabled = false,
  dropdownIndicator,
  errorLabel,
  inputId,
  isOpen,
  name,
  onChange,
  onClick,
  onKeyDown,
  placeholder,
  selectBoxStyles,
  selectBoxFocusedStyles,
  value,
  wrapperRef,
  wrapperStyles,
}) {
  const selectBoxPassStyles = [styles.selectBox];
  let arrowDirection = 'down';
  if (isOpen) {
    selectBoxPassStyles.push(styles.selectBoxFocused, selectBoxFocusedStyles);
    arrowDirection = 'up';
  }
  if (errorLabel) { selectBoxPassStyles.push(styles.selectBoxError); }
  selectBoxPassStyles.push(selectBoxStyles);

  const labelId = `${inputId}-label`.replace(/ /g, '-');

  return (
    <div
      css={[styles.wrapper, wrapperStyles]}
      onClick={onClick}
      ref={wrapperRef}
    >
      <input
        aria-labelledby={labelId}
        autoComplete={autoComplete}
        css={selectBoxPassStyles}
        disabled={disabled}
        id={inputId}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        value={value}
      />
      <div css={[styles.arrow, disabled && styles.arrowDisabled]}>
        {dropdownIndicator ? (
          <img
            src={dropdownIndicator}
          />) :
          <IconChevron
            color={disabled ? Colors.gray50() : Colors.rootOrange()}
            direction={arrowDirection}
          />}
      </div>
      <label
        css={styles.label}
        htmlFor={inputId}
        id={labelId}
      >
        {errorLabel || placeholder}
      </label>
      {children}
      {errorLabel && <span css={styles.labelError}>{errorLabel}</span>}
    </div>
  );
}

SelectBox.propTypes = {
  autoComplete: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  dropdownIndicator: PropTypes.string,
  errorLabel: PropTypes.string,
  inputId: PropTypes.string,
  isOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selectBoxFocusedStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectBoxStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  value: PropTypes.string,
  wrapperRef: PropTypes.object.isRequired,
  wrapperStyles: PropTypes.object,
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    height: 65,
    position: 'relative',
  },
  selectBox: {
    ...Theme.input(),
    caretColor: 'transparent',
    width: '100%',
    height: 65,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
    paddingRight: 35,
    ...Theme.roundedCorners(),
    border: `1px solid ${Colors.gray40()}`,
    color: Colors.gray50(),
    cursor: 'pointer',
    transition: 'all 200ms ease-in-out',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ':focus': {
      ...Shadows.hoverShadow(),
      borderColor: Colors.black(),
      outline: 'none',
    },
    ':disabled': {
      pointerEvents: 'none',
      cursor: 'unset',
      background: Colors.gray10(),
      borderColor: Colors.gray10(),
    },
  },
  selectBoxFocused: {
    ...Shadows.hoverShadow(),
    borderColor: Colors.black(),
  },
  selectBoxError: {
    borderColor: Colors.error(),
  },
  label: {
    display: 'none',
  },
  arrow: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    right: 14,
    transform: 'translateY(-50%)',
  },
  arrowDisabled: {
    cursor: 'unset',
  },
  labelError: {
    color: Colors.error(),
  },
});
