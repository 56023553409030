import * as Sentry from '@root/vendor/@sentry/browser';
import AppEntry from '@root/root-enterprise.com/src/app-entry';
import React from '@root/vendor/react';
import ReactDOM from '@root/vendor/react-dom';
import environment from '@root/core/src/utils/environment';
import { BrowserRouter } from '@root/vendor/react-router-dom';

if (Object.entries && window.URLSearchParams) {
  Sentry.init({
    dsn: environment.sentryDsn,
    release: environment.releaseSha,
    environment: environment.environment,
  });

  ReactDOM.render(
    <AppEntry routerComponent={BrowserRouter} />,
    document.getElementById('root')
  );
}
