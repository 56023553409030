import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function PrivacyPolicy() {
  return (
    <div
      css={styles.container}
      data-testid={'privacy-policy-scene'}
    >
      <div css={styles.innerContainer}>
        <h1>Voyager for Root Enterprise Privacy Policy</h1>
        <p css={styles.updated}>Last updated May 2021</p>
        <p>This Privacy Policy describes the privacy practices of the Root, Inc. family of companies  (“Root”, “us”, “we”, or “our”) for our insurance business on our website (“Site”) and/or mobile applications (“App”).  (
          <a href={'https://www.joinroot.com/app-eula'}><u>See Root App End User License Agreement</u></a>
          ).  This Privacy Policy applies to information collected by the Site and the App, and is intended to generally notify you of the following:
        </p>
        <ul>
          <li>What information we collect</li>
          <li>How we use and share your information</li>
          <li>How we secure and retain your information</li>
          <li>Your options and choices</li>
        </ul>
        <p>By using the Site or App you agree to this Privacy Policy and the collection and use of information contemplated by its terms. This is Root’s entire and exclusive Privacy Policy, and it supersedes any earlier version. This Privacy Policy applies to information we collect from all sources. If you feel that we are not abiding by this privacy policy, you should contact us immediately at
          <a href={'mailto:legal@joinroot.com'}> <u>legal@joinroot.com</u></a>.
        </p>

        <p>This policy does not apply to any non-Root affiliates. It also does not apply to the practices of our licensees or business partners. These might include, for example, vendors or advertisers.</p>

        <h3>WHAT INFORMATION WE COLLECT</h3>
        <p>For users of our Site and App, we gather public and nonpublic personally identifiable information from you and from outside business partners for business purposes. This public and nonpublic personally identifiable information we collect from you includes your:</p>
        <ul>
          <li>Name</li>
          <li>Phone Number</li>
          <li>Home and Email Addresses</li>
          <li>Driver License Number</li>
          <li>Marital Status</li>
          <li>Family Member Information</li>
        </ul>
        <p>Also, we maintain records that include, but are not limited to, insurance policy coverages, premiums, and payment history. The information we collect from outside business partners may include, but is not limited to, your driving record, claims history, and credit information.</p>
        <p>Our App. Outside of California, if you have granted Root permission to do so, we will gather certain telemetric data, such as accelerometer, GPS and gyroscope data, from your mobile device and continue to monitor your device activity until you otherwise change your mobile permissions.</p>
        <p>Additionally, the App and/or our business partners gather information through Internet activity, which may include, for example, your operating system, mobile device identification and/or advertising identification number, your phone carrier and interactions with other features within the mobile device while the App is installed.</p>
        <p>We store this information in an encrypted environment.</p>
        <p>Also note that because the Application incorporates Google Maps/Google Earth, you are also bound by the
          <a href={'https://maps.google.com/help/terms_maps.html'}> <u>Google Maps/Google Earth Additional Terms of Service</u> </a> and
          <a href={'https://www.google.com/intl/ALL/policies/privacy/index.html'}> <u>Google Privacy Policy</u></a>.
        </p>
        <p>Our Site. For users of our Website, we may use common tracking technologies like browser cookies, “Local Shared Objects” (such as “Flash” cookies), analytical tools, device IDs or other technologies. We also collect certain technical information about the device you use to access our site or services, such as user-agent, timestamp, hardware type, language and time-zone settings, IP address, OS type and version, and installed fonts. We do not support the necessary technology to respond to Web browser “do not track” signals or other similar mechanisms. If you click on links to third-party sites, you will be taken to websites we do not control. This Privacy Policy does not apply to the privacy practices of those websites. Read the privacy policy of other websites carefully. We are not responsible for third-party sites.</p>
        <h3>HOW WE USE AND SHARE YOUR INFORMATION</h3>
        <ul>
          <li>Root is the sole owner of the information collected from you on the App and Site.</li>
          <li>We collect information that you voluntarily give us via the sign-on process or other direct contact from you.</li>
          <li>We may also engage with third-party vendors to collect driving data from your vehicle, such as braking and acceleration. This data is securely stored in our environment in compliance with our retention policy.</li>
          <li>We may use, share, disseminate, or sell aggregated, anonymized data of consumers. This aggregated, anonymized data cannot be reasonably traceable to any individual.</li>
          <li>We will use your information to respond to you regarding the reason you contacted us.</li>
          <li>Unless you ask us not to, we may contact you via email, regular mail, and via push notification in the App with promotions to tell you about specials, new products or services.</li>
          <li>The App gathers and combines data from the mobile device for the purpose of analysis and risk evaluation, as well as for premium/rate calculation and claims investigations.</li>
          <li>Data collected by the app from your mobile device may be disclosed to third parties as required by law or regulation.</li>
          <li>Data collected by the app from your mobile device may be deemed discoverable by third parties and used in an accident investigation, claims, and/or litigation.</li>
          <li>Root may be legally required to provide the data to others and ⁄or their legal counsel, and you should not expect to retain data privacy or confidentiality.</li>
          <li>At our discretion, telematics can be utilized in the maintenance of your policy, investigation of a claim, or for any other lawful purposes.</li>
          <li>We may provide certain information to insurance regulators to support rate or other insurance-related filings.</li>
          <li>We will share your information with business partners as required or permitted by law. For example, we may share your information to:</li>
          <ul>
            <li>Fulfill a transaction you requested or to service your insurance policy</li>
            <li>Market our products or in furtherance of our business relationship with a marketing partner</li>
            <li>Handle your claim</li>
            <li>Prevent fraud</li>
            <li>Comply with requests from regulatory and law enforcement authorities</li>
            <li>Participate in insurance support organizations</li>
            <li>Send you push notifications</li>
          </ul>
          <li>The persons or organizations with whom we may share your information may include, among others:</li>
          <ul>
            <li>Your insurance agent, broker or affiliated companies</li>
            <li>Companies that perform services, such as marketing, credit/debit card processing, and communication services on our behalf</li>
            <li>Business partners that assist us with tracking driving behavior, scoring and usage</li>
            <li>Other financial institutions with whom we have a joint marketing agreement</li>
            <li>Other insurance companies that play a role in an insurance transaction with you</li>
            <li>Independent claims adjusters</li>
            <li>A business or businesses that conduct actuarial or research studies</li>
            <li>Those who request information pursuant to a subpoena or court order</li>
            <li>Repair shops and claims vendors</li>
          </ul>
        </ul>

        <h3>HOW WE SECURE AND RETAIN YOUR INFORMATION</h3>
        <p>Root takes precautions to protect your information. When you submit sensitive information via the Site or App, your information is protected both online and offline.</p>
        <p>Wherever we collect nonpublic or public personally identifiable information, that information is encrypted and transmitted to us or by us in a secure way. You can verify this by looking for a closed lock icon at the bottom of your web browser or looking for “https” at the beginning of the address of the web page.</p>
        <p>While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Our employees are prohibited from using or disclosing information for any purpose other than performing their work for Root.</p>

        <h3>YOUR OPTIONS AND CHOICES</h3>
        <p>By having an insurance policy with Root, you give us your consent to contact you with communications relating to your policy and our services.</p>
        <p>Review or correct your information. You can request to review personally identifiable information you have provided to Root. (This does not include information about you that was provided to Root by business partners or your telematics data collected with your permission.) To do this please send an email to
          <a href={'mailto:help@joinroot.com'}> <u>help@joinroot.com</u> </a>
          requesting to review the information you provided us. If you believe that our information is incomplete or inaccurate, you can request that we correct it. Please note we may not be able to provide information relating to investigations, claims, litigation, and other matters. We will be happy to make corrections whenever possible. We will respond to all such requests within a reasonable timeframe.
        </p>
        <p>Opt-out of marketing distribution. If you do not have, or no longer have a policy with us, you can contact us at
          <a href={'mailto:help@joinroot.com'}> <u>help@joinroot.com</u> </a>
          and request that we remove you from our marketing distribution lists. Please note that once you request removal, it may take up to 2 weeks to stop receiving email from us.
        </p>

        <h4>For Virginia Residents:</h4>
        <p>Pursuant to Virginia law, you have the right to access your personal data, request corrections, deletion, or make amendments to that data.</p>
        <p>Any information obtained from a report by an insurance support organization may be retained by the insurance support organization and disclosed to other persons.</p>

        <h4>For Montana Residents:</h4>
        <p>Pursuant to Montana law, you may request a record of any disclosures of your medical information during the preceding three years. Please send requests to
          <a href={'mailto:help@joinroot.com'}> <u>help@joinroot.com</u></a>.
        </p>

        <h4>Additional Information for California Residents:</h4>
        <p>Root does not collect any telematics data, location date, driving behavior or credit information for California consumers.</p>
        <p>You have the right to know what information is collected, disclosed or shared about you. You also have the right to have this information deleted when permitted by law, and to opt out of the sharing of any information. Here we explain how this works.</p>
        <p>Root collects personal information about you in its regular course of business. In addition to the information above, specific pieces of personal information collected, disclosed or shared is dependent on your relationship with us. In the past 12 months, Root has collected the following information for the following purposes:</p>
        <ul>
          <li>When you download the Root app or go on our website, we collect your mobile device information, your IP address, pixel information, cookies, analytical tools, OS type and version, timestamp, hardware type, language and time-zone setting and installed fonts.</li>
          <li>If you create a profile with us, we collect name, date of birth, driver’s license data, your phone number, marital status, homeowner status, physical address, email address, and Google/Facebook account identifiers. We will also collect this information about household drivers if added to a policy.</li>
          <li>If you create an account with us, we also collect vehicle information such as VIN, year/make/model, your annual mileage, and vehicle usage information. We also partner with third parties to collect driving violation information and claims and accident histories to offer you a quote for insurance.</li>
          <li>If you purchase a policy with us, we may also collect additional insured/lienholder information and payment information from you.</li>
          <li>If you have a claim with us, we collect claims related information including, but not limited to, vehicle information, involved parties, and medical information. Please note, we need to disclose to you that we would obtain your consent before sharing medical information for marketing purposes.</li>
          <li>We may collect information you provide to us through surveys, mobile permissions, and/or customer support interactions.</li>
        </ul>

        <p>Now that you are informed of the information we collect, disclose or share about you, you may have the right to delete this information.  Certain information may not be able to be deleted because of legal and business reasons.  We will inform you if this is the case after receiving a confirmation of your request to delete.  Click
          <a href={'http://www.joinroot.com/privacy/california'}> <u>here</u> </a>
          or contact us at
          <a href={'mailto:privacy@joinroot.com'}> <u>privacy@joinroot.com</u> </a>
          to request deletion of your information.
        </p>
        <p>You also have the right to opt out of the practice of sharing your information with third parties for business or marketing purposes. Click
          <a href={'http://www.joinroot.com/privacy/california'}> <u>here</u> </a>
          or you can contact us at
          <a href={'mailto:privacy@joinroot.com'}> <u>privacy@joinroot.com</u> </a>
          to request to opt-out.
        </p>
        <p>If you are unable to make these requests yourself, you can appoint an authorized agent to do so by providing the authorized agent written permission.  The agent can then contact us at
          <a href={'mailto:privacy@joinroot.com'}> <u>privacy@joinroot.com</u></a>.
        </p>
        <p>If you contact us via email, you will be required to provide verification with the following information:</p>
        <ul>
          <li>First and Last Name</li>
          <li>Email Address</li>
          <li>One of the following – policy number, full mailing address, or date of birth</li>
        </ul>
        <p>Any requests to delete information or opt out of sharing will not result in any discrimination of any kind.</p>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 63,
  },
  innerContainer: {
    width: '90%',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
  },
  updated: {
    color: 'gray',
  },
});
