import { objectsHaveSameKeys } from '@root/core/src/utils/objects-have-same-keys';
import { useCallback, useEffect, useRef, useState } from '@root/vendor/react';

const defaultValidations = [];

export default function useForm({
  validations = defaultValidations,
  initialValues = {},
  validateOnBlur = true,
}) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const controls = useRef({});
  const mounted = useRef(true);

  const isTouched = (fieldName) => !!controls.current?.[fieldName]?.touched;
  const isDirty = (fieldName) => !!controls.current?.[fieldName]?.dirty;

  useEffect(() => {
    if (Object.keys(controls.current).length && objectsHaveSameKeys(controls.current, values)) {
      return;
    }

    Object.keys(values).forEach((fieldName) => {
      controls.current[fieldName] = {
        touched: !!controls.current[fieldName]?.touched,
        dirty: !!controls.current[fieldName]?.dirty,
      };
    });
  }, [values]);

  const validate = useCallback(() => {
    const newErrors = {};

    validations.forEach((validation) => validation(values, newErrors));

    if (mounted.current) { setErrors(newErrors); }

    setIsValid(!Object.keys(newErrors).length);

    return newErrors;
  }, [validations, values]);

  useEffect(() => {
    if (validateOnBlur || submitted) {
      validate();
    }
  }, [submitted, validate, validateOnBlur, values]);

  useEffect(() => () => {
    mounted.current = false;
  }, [mounted]);

  const setFieldValue = (fieldName, value) => {
    setValues({
      ...values,
      [fieldName]: value,
    });
  };

  const initFieldControl = (fieldName) => {
    if (controls.current[fieldName] === undefined) {
      controls.current[fieldName] = {
        dirty: false,
        touched: false,
      };
    }
  };

  const setFieldDirty = (fieldName, dirty = true) => {
    initFieldControl(fieldName);
    controls.current[fieldName].dirty = dirty;
  };

  const setFieldTouched = (fieldName, touched = true) => {
    initFieldControl(fieldName);
    controls.current[fieldName].touched = touched;
  };

  const validateField = (fieldName) => {
    const newErrors = {};

    validations.forEach((validation) => validation(values, newErrors));
    const updatedErrors = {
      ...errors,
      [fieldName]: newErrors[fieldName],
    };
    setErrors(updatedErrors);
  };

  const getFieldProps = (fieldName, { sanitize } = {}) => {
    const value = values[fieldName] === undefined || values[fieldName] === null ? '' : values[fieldName];

    const onChange = (newValue) => {
      const sanitizedValue = sanitize ? sanitize(newValue) : newValue;
      setFieldValue(fieldName, sanitizedValue);
      setFieldDirty(fieldName);
    };

    const onBlur = () => {
      setFieldTouched(fieldName);
    };

    const hasError = () => {
      return !!errors[fieldName];
    };

    const errorLabel = () => {
      if (hasError() && (isTouched(fieldName) || submitted)) {
        return errors[fieldName];
      }
    };

    const props = {
      onBlur,
      onChange,
      value,
      'aria-invalid': hasError(),
      errorLabel: errorLabel(),
      isError: hasError(),
    };

    if (validateOnBlur) {
      props.onBlur = () => {
        onBlur();
        validateField(fieldName);
      };
    }

    return props;
  };

  const createSubmitHandler = (submit) => async (event) => {
    event?.preventDefault?.();

    Object.keys(controls.current).forEach((fieldName) => {
      setFieldDirty(fieldName);
      setFieldTouched(fieldName);
    });

    const validationErrors = validate();

    if (Object.keys(validationErrors).length) {
      setIsValid(false);
      return;
    }

    setSubmitting(true);
    setSubmitted(true);

    submit && await submit(values);

    if (mounted.current) {
      setSubmitting(false);
    }
  };

  const resetForm = () => {
    if (mounted.current) {
      controls.current = {};
      setValues(initialValues);
      setErrors({});
      setIsValid(false);
      setSubmitted(false);
      setSubmitting(false);
    }
  };

  const setFieldError = (fieldName, error) => {
    setErrors({
      ...errors,
      [fieldName]: error,
    });
    setFieldDirty(fieldName);
    setFieldTouched(fieldName);
  };

  return {
    values,
    errors,
    isDirty,
    isTouched,
    isValid,
    createSubmitHandler,
    getFieldProps,
    submitting,
    submitted,
    setSubmitting,
    validate,
    resetForm,
    setFieldError,
  };
}
