import DayPickerInput from 'react-day-picker/DayPickerInput';
import KeyCode from '@root/core/src/utils/keycode';
import PropTypes from '@root/vendor/prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import calendar from '@root/core/src/assets/calendar.svg';
import dayjs from '@root/vendor/dayjs';
import { Colors, StyleSheet, Theme, createEmotionClassName } from '@root/core/src/utils/styles';
import { SelectBox } from '@root/core/src/components/select';
import { dayPickerDropdownStyles, dayPickerInputStyles, dayPickerStyles } from '@root/core/src/utils/datepicker-styles';

const DropdownInput = forwardRef(({
  isOverlayOpen, overlayRef, ...props
}, ref) => {
  const [isOpen, setIsOpen] = useState(isOverlayOpen);
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setIsOpen(false);
        props.onBlur();
      }
    };

    const handleKeydown = (event) => {
      if (overlayRef.current && event.keyCode === KeyCode.KEYCODES.ESC) {
        props.onBlur();
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [overlayRef, props]);

  const handleClick = () => {
    isOpen ? props.onBlur() : props.onClick();
    setIsOpen((prev) => !prev);
  };

  return (
    <SelectBox
      {...props}
      isOpen={isOpen}
      name={'date'}
      onClick={handleClick}
      wrapperRef={inputRef}
    />);
});

DropdownInput.propTypes = {
  isOverlayOpen: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  overlayRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

const CustomOverlay = forwardRef(({
  classNames,
  children,
  selectedDay, // eslint-disable-line no-unused-vars
  month, // eslint-disable-line no-unused-vars
  ...props
}, overlayRef) => {
  return (
    <div
      className={classNames.overlayWrapper}
      ref={overlayRef}
      {...props}
    >
      <div className={classNames.overlay}>
        {children}
      </div>
    </div>
  );
});

CustomOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.object.isRequired,
  month: PropTypes.instanceOf(Date),
  overlayRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  selectedDay: PropTypes.instanceOf(Date),
};

export default function Datepicker({
  dateLimit = null,
  disablePastDays = false,
  errorLabel,
  inputStyles = dayPickerInputStyles,
  isError = false,
  label,
  onDateChange,
  onDayPickerHide,
  onDayPickerShow,
  pickerStyles = dayPickerStyles,
  showDropdown = false,
  today = new Date(),
  ...props
}) {
  const [selectedDay, setSelectedDay] = useState(props.selectedDay);

  const weekdaysShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const dateFormat = 'MMM DD, YYYY';

  const inputId = props.inputId || label.toLowerCase().replace(' ', '-') + '-input';
  const labelId = inputId + '-label';
  const iconLabelId = inputId + '-icon-label';

  const selectedDayIsValid = (past, outside) => (!disablePastDays || !past) && (!dateLimit || !outside);

  const selectDay = (day, { past, outside }) => {
    if (selectedDayIsValid(past, outside)) {
      setSelectedDay(day);
      onDateChange(day);
    }
  };

  const formatDate = (date) => {
    let dateToFormat = selectedDay;
    let format = 'M/D/YY';

    if ((!disablePastDays || date >= today) && (!dateLimit || date <= dateLimit)) {
      dateToFormat = date;
    }

    if (Responsive.matches(Responsive.QUERIES.sm)) {
      format = 'MMM D, YYYY';
    }

    return dayjs(dateToFormat).format(format);
  };

  const containerStyles = [inputStyles.container];
  if (isError || errorLabel) {
    containerStyles.push(inputStyles.errorContainer);
  }

  const dayPickerInputClassNames = {
    container: createEmotionClassName(containerStyles),
    overlayWrapper: createEmotionClassName(inputStyles.overlayWrapper),
    overlay: createEmotionClassName(inputStyles.overlay),
  };

  const dayPickerClassNames = {
    container: createEmotionClassName(showDropdown ? dayPickerDropdownStyles.container : pickerStyles.container),
    wrapper: createEmotionClassName(pickerStyles.wrapper),
    interactionDisabled: createEmotionClassName(pickerStyles.interactionDisabled),
    navBar: createEmotionClassName(pickerStyles.navBar),
    navButtonPrev: createEmotionClassName(pickerStyles.navButtonPrev),
    navButtonNext: createEmotionClassName(showDropdown ? dayPickerDropdownStyles.navButtonNext : pickerStyles.navButtonNext),
    navButtonInteractionDisabled: createEmotionClassName(pickerStyles.navButtonInteractionDisabled),
    months: createEmotionClassName(pickerStyles.months),
    month: createEmotionClassName(pickerStyles.month),
    caption: createEmotionClassName(pickerStyles.caption),
    weekdays: createEmotionClassName(pickerStyles.weekdays),
    weekdaysRow: createEmotionClassName(pickerStyles.weekdaysRow),
    weekday: createEmotionClassName(pickerStyles.weekday),
    weekNumber: createEmotionClassName(pickerStyles.weekday),
    body: createEmotionClassName(pickerStyles.body),
    week: createEmotionClassName(pickerStyles.week),
    day: createEmotionClassName(pickerStyles.day),
    footer: createEmotionClassName(pickerStyles.footer),
    todayButton: createEmotionClassName(pickerStyles.todayButton),
    today: '',
    selected: createEmotionClassName(pickerStyles.selected),
    disabled: createEmotionClassName(pickerStyles.disabled),
    outside: createEmotionClassName(pickerStyles.outside),
    past: createEmotionClassName(pickerStyles.past),
  };

  const dayPickerProps = {
    onDayClick: selectDay,
    selectedDays: selectedDay,
    weekdaysShort,
    modifiers: {
      today,
    },
    classNames: dayPickerClassNames,
  };

  if (disablePastDays) {
    dayPickerProps.fromMonth = today;
    dayPickerProps.modifiers.past = {
      before: today,
    };
  }

  if (dateLimit) {
    dayPickerProps.toMonth = dateLimit;
    dayPickerProps.modifiers.outside = {
      after: dateLimit,
    };
  }

  const inputProps = {
    readOnly: true,
    'data-isdaypickerinput': true,
    id: inputId,
  };

  const labelStyles = [styles.labelFull,
    isError || errorLabel ? styles.errorLabel : '',
  ];

  const overlayRef = useRef();
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const handleDayPickerHide = () => {
    setIsOverlayOpen(false);
    onDayPickerHide?.();
  };

  const handleDayPickerShow = () => {
    setIsOverlayOpen(true);
    onDayPickerShow?.();
  };

  const renderDropdownComponent = forwardRef((componentProps, ref) => (
    <DropdownInput
      {...componentProps}
      isOverlayOpen={isOverlayOpen}
      overlayRef={overlayRef}
      ref={ref}
      selectBoxFocusedStyles={props.selectBoxFocusedStyles}
      selectBoxStyles={props.selectBoxStyles}
      wrapperStyles={props.selectBoxWrapperStyles}
    />)
  );

  const renderOverlayComponent = (overlayProps) => (
    <CustomOverlay
      {...overlayProps}
      ref={overlayRef}
    />);

  return (
    <div css={styles.wrapper}>
      <DayPickerInput
        classNames={dayPickerInputClassNames}
        component={showDropdown ? renderDropdownComponent : undefined}
        dayPickerProps={dayPickerProps}
        format={dateFormat}
        formatDate={formatDate}
        hideOnDayClick={true}
        inputProps={inputProps}
        keepFocus={true}
        onDayPickerHide={handleDayPickerHide}
        onDayPickerShow={handleDayPickerShow}
        overlayComponent={showDropdown ? renderOverlayComponent : undefined}
        placeholder={'Choose date'}
        value={selectedDay || ''}
      />
      {!showDropdown &&
      <>
        <label
          css={labelStyles}
          htmlFor={inputId}
          id={labelId}
        >
          {errorLabel || label}
        </label>
        <label
          css={[styles.labelFull, styles.labelRight, inputStyles.iconLabel]}
          htmlFor={inputId}
          id={iconLabelId}
        >
          <img
            alt={'Calendar'}
            src={calendar}
          />
        </label>
      </>
      }
    </div>
  );
}

Datepicker.propTypes = {
  dateLimit: PropTypes.instanceOf(Date),
  disablePastDays: PropTypes.bool,
  errorLabel: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  inputStyles: PropTypes.object,
  isError: PropTypes.bool,
  isSmall: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onDayPickerHide: PropTypes.func,
  onDayPickerShow: PropTypes.func,
  pickerStyles: PropTypes.object,
  selectBoxFocusedStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectBoxStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectBoxWrapperStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedDay: PropTypes.instanceOf(Date),
  showDropdown: PropTypes.bool,
  today: PropTypes.instanceOf(Date),
};

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
  },
  labelFull: {
    ...Theme.paragraph1(),
    pointerEvents: 'none',
    paddingLeft: '15px',
    position: 'absolute',
    top: 17,
    left: 0,
    display: 'flex',
    alignItems: 'center',
  },
  labelRight: {
    right: 0,
    left: 'auto',
    paddingRight: '15px',
  },
  errorLabel: {
    color: Colors.error(),
  },
});
