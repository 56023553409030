import Animation from 'lottie-web/build/player/lottie_light.min';

export const opacityKeyframes = {
  'from': {
    opacity: 0,
  },
  'to': {
    opacity: 1,
  },
};

export default Animation;
